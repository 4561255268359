/**============================================================
*   [HeaderNavigation]
*   description : カテゴリメニューの開閉処理
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  const gnav = document.getElementById('gnav');
  const body = document.body;
  const toggleGnav = () => {
    gnav.classList.toggle('is-open');
    body.classList.toggle('is-open');
  };

  document.getElementById('category-button')?.addEventListener('click', toggleGnav);
  document.querySelector('.l-gnav__close')?.addEventListener('click', () => {
    gnav.classList.remove('is-open');
    body.classList.remove('is-open');
  });
});
