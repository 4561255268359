/**============================================================
*   [scrollTop]
*   description : 「TOPに戻る」ボタン処理
============================================================*/
let currentWidth = window.innerWidth;
const button = document.getElementById('scroll-top-fixed');

if (button) {
  const observer = new IntersectionObserver((entries) => {
  for (const e of entries) {
      if (e.isIntersecting) {
        button.classList.remove('is-view');
      } else {
        button.classList.add('is-view');
      }
    }
  });
  observer.observe(document.getElementById('top'));

  window.addEventListener('DOMContentLoaded', observer);
  window.addEventListener('resize', debounce(function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    observer;
  }), 200);
}

function debounce(func, delay) {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}
