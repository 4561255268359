// mixin for SmartFit SizeFinder Service with Custom Fallback
// sets a global scope object due to requirements of external script

export default {
    events: {
        'click #sizefinder-inseam-submit': 'calculateFromInseam',
        'touch #sizefinder-inseam-submit': 'calculateFromInseam',
        'keydown #sizefinder-inseam-input': 'keyPressed',
        'click #sizefinder-select-on-pdp': 'setFrameSize',
        'touch #sizefinder-select-on-pdp': 'setFrameSize',
    },

    reinitializeEvents() {
        const submitButton = document.querySelector('#sizefinder-inseam-submit');
        const inseamInput = document.querySelector('#sizefinder-inseam-input');
        const sizeFinderSelect = document.querySelector('#sizefinder-select-on-pdp');

        if (!submitButton && !inseamInput) {
            return;
        }

        submitButton.addEventListener('click', () => {
            this.calculateFromInseam();
        });
        submitButton.addEventListener('touch', () => {
            this.calculateFromInseam();
        });

        inseamInput.addEventListener('keydown', (e) => {
            this.keyPressed(e);
        });

        sizeFinderSelect.addEventListener('click', () => {
            this.setFrameSize();
        });
        sizeFinderSelect.addEventListener('touch', () => {
            this.setFrameSize();
        });
    },

    // callback to set result to PDP
    setFrameSize() {
        const result = window.onlineSizer.result.ean || window.onlineSizer.result.frameHeight;
        let resultIndex;
        if (window.onlineSizer.result.ean) {
            resultIndex = window.onlineSizer.context.available
                .find(s => s.eans.some(ean => ean === result))
                .index;
        } else if (window.onlineSizer.result.frameHeight) {
            resultIndex = window.onlineSizer.context.available
                .find(s => s.frameSizes.some(frameSize => Number.parseFloat(frameSize) === result))
                .index;
        }

        document.querySelectorAll('.js-selectVariation')[resultIndex].dispatchEvent(new Event('click', {
            bubbles: true,
        }));

        const isModalActive = document.body.classList.contains('modal-active');
        if (isModalActive) {
            const closeModalBtn = document.querySelector('#modal .js-closeDialog');
            const event = new Event('click', { bubbles: true });
            closeModalBtn.dispatchEvent(event);
        }
    },

    // callback to check availability of framesize/ean
    checkAvailability() {
        const result = window.onlineSizer.result.ean || window.onlineSizer.result.frameHeight;
        // if result is available as ean
        if (window.onlineSizer.result.ean) {
            return window.onlineSizer.context.available
                .some(option => option.eans.some(ean => ean === result));
        } else if (window.onlineSizer.result.frameHeight) {
            return window.onlineSizer.context.available
                .some(option => option.frameSizes.some(frameSize => Number.parseFloat(frameSize) === result));
        }
        return false;
    },

    // callback to link custom sizefinder if smartfit fails
    linkCustomSizeFinder() {
        const { context } = window.onlineSizer;
        context.useCustomSizer = true; // temp
        context.triggerElement.href = context.config.customSizeFinder.link;
        context.triggerElement.dataset.dialogHeading = context.config.customSizeFinder.heading;
        context.triggerElement.classList.add('dialoglink');
        window.console.log('Using Custom SizeFinder');
    },

    // calculate framesize from user input
    calculateFromInseam() {
        const inseamInput = document.querySelector('#sizefinder-inseam-input')
            .value
            .replace(/,/, '.');
        document.querySelectorAll('.sizefinder-message')
            .forEach(el => el.classList.add('hidden'));
        // validation
        if (
            !/^[0-9]+\.?[0-9]*$/.test(inseamInput)
            || Number.parseFloat(inseamInput) < 30
            || Number.parseFloat(inseamInput) > 130
        ) {
            document.querySelector('#sizefinder-message-validationerror').classList.remove('hidden');
            return;
        }
        // do the calculation
        const calcFactor = Number.parseFloat(document.querySelector('#sizefinder-inseam-submit').dataset.calc);
        const frameHeight = Math.round(calcFactor * Number.parseFloat(inseamInput));
        if (!frameHeight) return;
        document.querySelector('#sizefinder-result').innerHTML = frameHeight;
        document.querySelector('#sizefinder-message-result').classList.remove('hidden');
        // see if running on PDP (onlinesizer global object available)
        if (window.onlineSizer) {
            // set result to global object
            window.onlineSizer.result = { frameHeight };
            // check availabity
            if (this.checkAvailability()) {
                document.querySelector('#sizefinder-message-available').classList.remove('hidden');
            } else {
                document.querySelector('#sizefinder-message-outofstock').classList.remove('hidden');
            }
        }
    },

    // callback to submit by keyboard
    keyPressed: (event) => {
        if (event.key === 'Enter') {
            this.calculateFromInseam();
        }
    },

    initialize() {
        // set context
        const context = {};
        context.triggerElement = document.querySelector('#trigger-onlineSizer');
        if (!context.triggerElement) return;
        context.selectElement = document.querySelector('.sizefinder-select');
        context.config = JSON.parse(context.triggerElement.dataset.config);
        context.available = context.selectElement
            ? Array.from(context.selectElement.querySelectorAll('.js-selectVariation'))
                .reduce((a, e, i) => {
                    a.push({
                        index: i,
                        eans: e.dataset.eans ? JSON.parse(e.dataset.eans) : [],
                        frameSizes: e.dataset.framesizes ? JSON.parse(e.dataset.framesizes) : [],
                    });
                    return a;
                }, [])
            : [];

        // try smartfit
        // set smartFit global object
        window.onlineSizer = {
            bikeName: context.config.bikeName,
            bikeImageUrl: context.config.bikeImageUrl,
            context,
            widgetUrl: 'https://fahrrad-de.onlinesizer.de/xhr-onlinesizer.en.html',
            api: {
                url: 'https://api.smartfit.bike',
                username: 'fahrrad.de',
                password: 'sdg!p3pjpLd79K&2jzhiCt',
            },
            ean: context.config.ean,
            finish: {
                action: this.setFrameSize,
                buttonText: context.config.finishText,
            },
            soldOut: {
                checkAvailability: this.checkAvailability,
                action: 'closeOverlay',
                buttonText: context.config.soldOutText,
            },
            outOfRange: {
                buttonText: context.config.outOfRangeText,
            },
            eanNotFound: this.linkCustomSizeFinder,
            enableStoreSessionData: true,
            enableLockBodyScrolling: true,
            debug: false,
        };
        // insert smartFit script
        const script = document.createElement('script');
        script.src = 'https://fahrrad-de.onlinesizer.de/assets/js/onlinesizer.js';
        script.onerror = this.linkCustomSizeFinder;
        script.charset = 'utf-8';
        document.body.appendChild(script);

        // remove after exec to prevent buildup from reinitializing
        script.remove();

        // insert target div if not present
        if (!document.querySelector('#onlinesizer-target')) {
            const target = document.createElement('div');
            target.id = 'onlinesizer-target';
            target.classList.add('smartfit-onlinesizing');
            // prevent events inside external overlay from interacting with our views
            target.addEventListener('click', e => e.stopPropagation());
            target.addEventListener('touch', e => e.stopPropagation());
            target.addEventListener('change', e => e.stopPropagation());
            document.body.appendChild(target);
        }
    },
};
