/**============================================================
*   [moreDisplay]
*   description : 非表示要素の表示処理
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  const moreLists = document.querySelectorAll('.js-more-list');

  const hideExcessItems = (items, maxItems) => {
    Array.from(items).slice(maxItems).forEach(item => {
      item.style.display = 'none';
    });
  };

  const showAllItems = (items, button) => {
    Array.from(items).forEach(item => {
      item.style.display = 'block';
    });
    button.style.display = 'none';
  };

  if (moreLists) {
    moreLists.forEach(moreList => {
      const moreItems = moreList.querySelectorAll('.js-more-list__item');
      const moreButton = moreList.querySelector('.js-more-list__button');
      let maxItems;

      if (moreButton) {
        if (moreList.classList.contains('p-news')) {
          maxItems = 2;
        } else if (moreList.classList.contains('p-brand')) {
          maxItems = 12;
        }

        if (moreItems.length <= maxItems) {
          moreButton.style.display = 'none';
        } else {
          hideExcessItems(moreItems, maxItems);
        }

        moreButton.addEventListener('click', () => {
          showAllItems(moreItems, moreButton);
        });
      }
    });
  }
});
