/**============================================================
*   [setVers]
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  const headerElem = document.getElementById('header-navigation');
  const footerElem = document.getElementById('footer-navigation');

  //ヘッダーの高さ
  if(headerElem !== null){
    window.addEventListener('load', setHeaderHeight, true);
    window.addEventListener('resize', setHeaderHeight, true);
  }
  function setHeaderHeight() {
    document.documentElement.style.setProperty('--header-H', headerElem.getBoundingClientRect().height+'px');
  }

  //フッターの高さ
  if(footerElem !== null){
    window.addEventListener('load', setFooterHeight, true);
    window.addEventListener('resize', setFooterHeight, true);
  }
  function setFooterHeight() {
    document.documentElement.style.setProperty('--footer-H', footerElem.getBoundingClientRect().height+'px');
  }
});
