/**============================================================
*   [topSlider]
*   description : TOPページスライダー機能
============================================================*/
import Splide from '@splidejs/splide';

const topKvSlider = () => {
  const slider = document.getElementById('topKvSlider');

  if (slider) {
    const slideLength = slider.querySelectorAll(".splide__slide").length;
    const isDestroy = slideLength === 1 ? "completely" : false;

    new Splide('#topKvSlider', {
      autoplay: true,
      type: 'loop',
      pauseOnHover: false,
      pauseOnFocus: false,
      interval: 4000,
      speed: 1000,
      arrows: slideLength === 1 ? false : true,
      destroy: isDestroy,
    }).mount();
  }
};

document.addEventListener('DOMContentLoaded', topKvSlider);
