/**============================================================
*   [blogTopKvSlider]
*   description : ブログTOPページスライダー機能
============================================================*/
import BlogSplide from '@splidejs/splide';

const blogTopKvSlider = () => {
  const blogTopKvSliderElem = document.getElementById('blogTopKvSlider');
  if (blogTopKvSliderElem) {
    new BlogSplide('#blogTopKvSlider', {
      autoplay: true,
      type: 'loop',
      pauseOnHover: false,
      pauseOnFocus: false,
      interval: 4000,
      speed: 1000,
      trimSpace: true,
      focus: 'center'
    }).mount();
  }
};

document.addEventListener('DOMContentLoaded', blogTopKvSlider);
