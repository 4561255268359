/**============================================================
*   [sidebarAccordion]
*   description : サイドバーのアコーディオン処理
============================================================*/
document.addEventListener('DOMContentLoaded', function() {
  const sidebarToggleButtons = document.querySelectorAll('.p-sidebar__link--parent');
  const productContainer = document.querySelector('.p-pbs-product');

  sidebarToggleButtons.forEach(function(toggleBtn) {
    toggleBtn.addEventListener('click', function() {
      const list = this.closest('.p-sidebar__item--group');
      list.classList.toggle('is-open');
    });
  });

  if (productContainer === null) {
    //カレント表示
    // URLのパラメータを取得
    const urlParam = location.search.substring(1);

    // URLにパラメータが存在する場合
    if(urlParam) {
      // 「&」が含まれている場合は「&」で分割
      const param = urlParam.split('&');

      // パラメータを格納する用の配列を用意
      const paramArray = [];

      // 用意した配列にパラメータを格納(paramArray.modeを使用)
      for (let i = 0; i < param.length; i++) {
        const paramItem = param[i].split('=');
        paramArray[paramItem[0]] = paramItem[1];
      }

      //hrefにカレントクラス設定、アコーディオンの場合はオープン
      const sidebarLinks = document.querySelectorAll('a.p-sidebar__link,a.p-sidebar__sub-list-link');
      sidebarLinks.forEach(function(linkItem) {
        const href = linkItem.getAttribute('href');
        const hrefParam = new URLSearchParams(href.substring(href.indexOf('?')));
        const modeValue = hrefParam.get('mode');
        if(modeValue === paramArray.mode){
          linkItem.classList.add('is-current');
          const groupParent = linkItem.closest('.p-sidebar__item--group');
          if(groupParent){
            groupParent.classList.add('is-open');
          }
        }
      })
    }
  }
});
