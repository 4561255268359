/**============================================================
*   [modal]
*   description : モーダル機能
============================================================*/
import MicroModal from 'micromodal';

const initializeMicroModal = () => {
  MicroModal.init({
    disableScroll: true,
    awaitCloseAnimation: true,
    awaitOpenAnimation: true
  });
};
document.addEventListener('DOMContentLoaded', initializeMicroModal);

const openOtherPageModal = () => {
  const modalButton = document.getElementById('modalButtonSupportPlus');

  if (modalButton) {
    fetch('/?mode=f19')
      .then(response => {
        return response.arrayBuffer();
      })
      .then(buffer => {
        const decoder = new TextDecoder('euc-jp');
        const data = decoder.decode(buffer);

        const tempElement = document.createElement('div');
        tempElement.innerHTML = data;
        const mainContent = tempElement.querySelector('#mainContent').innerHTML;
        document.getElementById('modalContentSupportPlus').innerHTML = mainContent;
      });
  }
};
document.addEventListener('DOMContentLoaded', openOtherPageModal);
