/**============================================================
*   [anchor]
*   description : ページ内リンクの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  const anchorLinks = document.querySelectorAll('a[href*="#"]:not(a[href*="?mode="])')
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  let header = document.getElementById('header-navigation');
  let headerHeight = (header) ? header.offsetHeight : 0;

  //headerの高さ算出
  const mqlAnchor = window.matchMedia('(min-width: 768px)');
  function mqlAnchorCheck() {
    headerHeight = (header) ? header.offsetHeight : 0;
  }
  mqlAnchorCheck(mqlAnchor);
  mqlAnchor.addEventListener('change', mqlAnchorCheck, false);

  //アンカークリック時
  anchorLinksArr.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      const targetId = link.hash.replace('#','');
      const targetElement = document.getElementById(targetId);
      const targetElementSection = document.getElementById(targetId + '-section');
      let targetOffsetTop;
      if(targetElementSection){
        targetOffsetTop = window.pageYOffset + targetElementSection.getBoundingClientRect().top;
      }else{
        targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      }
      const totalScrollAmount = targetOffsetTop - headerHeight;
      window.scrollTo({
        top: totalScrollAmount,
        behavior: 'smooth'
      });
    });
  });

  //ロード時：ハッシュがある場合該当箇所へ移動
  window.addEventListener('load', () => {
    const targetId = window.location.hash.replace('#','');
    if(targetId){
      const targetElement = document.getElementById(targetId);
      const targetElementSection = document.getElementById(targetId + '-section');
      let targetOffsetTop;
      if(targetElementSection){
        targetOffsetTop = window.pageYOffset + targetElementSection.getBoundingClientRect().top;
      }else if(targetElement){
        targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      }
      let totalScrollAmount = targetOffsetTop - headerHeight;
      window.scrollTo({
        top: totalScrollAmount,
        behavior: 'smooth'
      });
    }
  });
});
