/**============================================================
*   [changeCartNum]
*   description : カート個数処理
============================================================*/
const input = document.querySelector('input[name="product_num"]');
const quantityDown = document.querySelector('.quantity-down');
const quantityUp = document.querySelector('.quantity-up');

const changeCartNum = () => {
  if (input && quantityDown && quantityUp) {
    quantityDown.addEventListener('click', function () {
      let value = parseInt(input.value);
      value = Math.max(value - 1, 1);
      input.value = value;
    });

    quantityUp.addEventListener('click', function () {
      let value = parseInt(input.value);
      value += 1;
      input.value = value;
    });
  }
}

window.addEventListener('DOMContentLoaded', changeCartNum, true);
