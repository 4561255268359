/**============================================================
*   [addHeaderAnchor]
*   description : 記事内のhタグにアンカーリンクを設置
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  const articleIndex = document.querySelector('.p-blog-article__index');

  if (articleIndex) {
    const article = document.querySelector('.p-blog-article__body');
    const headers = Array.from(article.querySelectorAll('h2, h3'));

    let leadNumber = '';

    headers.forEach((header, index) => {
      if (header.tagName === 'H2') {
        if (index === 0) {
          leadNumber = 0;
        }
        header.setAttribute('id', 'sec0' + (leadNumber + 1));
        leadNumber++;
      } else {
        const prevId = headers[index - 1].getAttribute('id');

        if (prevId.includes('_')) {
          const firstNumber = prevId.replace(/_.*/, '');
          const lastNumber = Number(prevId.match(/\d+$/)[0]) + 1;
          header.setAttribute('id', firstNumber + '_' + lastNumber);
        } else {
          header.setAttribute('id', prevId + '_1');
        }
      }
    });
  }
});
