/**============================================================
*   [blogRecommendSlider]
*   description : ブログレコメンドスライダー機能
============================================================*/
import BlogRecommendSplide from '@splidejs/splide';

const blogRecommendSlider = () => {
    const blogRecommendSliderTarget = document.getElementById('blogRecommendSlider');
    if (blogRecommendSliderTarget) {
      new BlogRecommendSplide('#blogRecommendSlider', {
        autoplay: false,
        type: 'loop',
        pagination: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        interval: 4000,
        speed: 1000,
        trimSpace: true,
        focus: 'center'
      }).mount();
    }
};

document.addEventListener('DOMContentLoaded', blogRecommendSlider);

