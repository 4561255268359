/**============================================================
*   [gnavAccordion]
*   description : グローバルナビ内のアコーディオン処理
============================================================*/
let currentWidth = window.innerWidth;

const gnavSublistAccordion = () => {
  const parentTitles = document.querySelectorAll('.parent-title');
  if (parentTitles) {
    parentTitles.forEach(parentTitle => {
      const parent = parentTitle.closest('.parent');
      if (!parentTitle.hasEventLister) {
        parentTitle.addEventListener('click', function () {
          parent.classList.toggle('is-open');
        });
      }
    });
  }
}

const gnavTitleAccordion = () => {
  const titleElements = document.getElementsByClassName('l-gnav-item__title');
  if (window.innerWidth <= 767 && titleElements) {
    for (var i = 0; i < titleElements.length; i++) {
      const titleElement = titleElements[i];
      if (!titleElement.hasEventLister) {
        titleElement.addEventListener('click', function () {
          const listItem = this.closest('.l-gnav-item');
          if (listItem) {
            listItem.classList.toggle('is-open');
          }
        });
      }
    }
  }
}

const gnavGenreAccordion = () => {
  const titleElements = document.getElementsByClassName('l-gnav-genre__title');
  const linkElements = document.getElementsByClassName('l-gnav-genre__link');

  if (window.innerWidth <= 767 && titleElements) {
    for (let i = 0; i < titleElements.length; i++) {
      const titleElement = titleElements[i];
      if (!titleElement.hasEventLister) {
        titleElement.addEventListener('click', function () {
          const listItem = this.closest('.l-gnav-genre');
          if (listItem) {
            listItem.classList.toggle('is-open');
          }
        });
      }
    }
  }

  if (linkElements) {
    for (let i = 0; i < linkElements.length; i++) {
      const linkElement = linkElements[i];
      if (!linkElement.hasEventLister) {
        linkElement.addEventListener('click', function () {
          const listItem = this.parentElement;
          if (listItem) {
            listItem.classList.toggle('is-open');
          }
        });
      }
    }
  }
}

window.addEventListener('DOMContentLoaded', gnavSublistAccordion);

window.addEventListener('DOMContentLoaded', gnavTitleAccordion);
window.addEventListener('resize', debounce(function () {
  if (currentWidth == window.innerWidth) {
    return;
  }
  currentWidth = window.innerWidth;
  gnavTitleAccordion();
}), 200);

window.addEventListener('DOMContentLoaded', gnavGenreAccordion);
window.addEventListener('resize', debounce(function () {
  if (currentWidth == window.innerWidth) {
    return;
  }
  currentWidth = window.innerWidth;
  gnavGenreAccordion();
}), 200);

function debounce(func, delay) {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}
