/**============================================================
*   [advisorAccordion]
*   description : ADVISORマウスオーバー処理
============================================================*/
let currentWidth = window.innerWidth;
const advisorItems = document.querySelectorAll('.p-advisor__item');

const advisorAccordion = () => {

  advisorItems.forEach((item) => {
    if (window.innerWidth >= 767) {
      item.addEventListener('mouseover', function () {
        item.classList.add('is-open');
      });

      item.addEventListener('mouseout', function () {
        item.classList.remove('is-open');
      });

    } else {
      const itemTitle = item.querySelector('.p-advisor__item-title');
      if (!itemTitle.hasEventListener) {
        itemTitle.addEventListener('click', function () {
          item.classList.toggle('is-open');
        });
      }
    }
  });
}

window.addEventListener('DOMContentLoaded', advisorAccordion);
window.addEventListener('resize', debounce(function () {
  if (currentWidth == window.innerWidth) {
    return;
  }
  currentWidth = window.innerWidth;
  advisorAccordion();
}, 200));

function debounce(func, delay) {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}
