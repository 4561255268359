/**============================================================
*   [fixHeight]
*   description : 高さ揃えの処理
============================================================*/
// 高さを揃える要素
const target = document.querySelectorAll('.js-fix-height-box');

function fixHeight() {
  const heightList = [];
  target.forEach(element => {
    const children = element.querySelectorAll('.js-fix-height-box__item');

    children.forEach(child => {
      child.setAttribute('style','');
      const height = child.clientHeight;
      heightList.push(height);
    });

    //一番高さがある要素のheightを取得。
    const maxHeight = Math.max.apply(null, heightList);

    children.forEach(child => {
      child.style.height = maxHeight + 'px';
    });
  });
}

function addFixHeightEvent() {
  if (window.matchMedia('(min-width: 768px)').matches) {
    //PC
    fixHeight();
    window.addEventListener('load', fixHeight);
    window.addEventListener('resize', fixHeight, 200);
  }else {
    window.removeEventListener('resize', fixHeight, 200);
  }
}

const mqlFixHeight = window.matchMedia('(min-width: 768px)');
const mqlFixHeightReset = (e) => {
  addFixHeightEvent();
  if (!e.matches) {
    //SP
    const items = document.querySelectorAll('.js-fix-height-box__item');
    items.forEach(item => {
      item.setAttribute('style','');
    })
  }
}
mqlFixHeightReset(mqlFixHeight);
mqlFixHeight.addEventListener('change', mqlFixHeightReset, false);
