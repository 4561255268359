/**============================================================
*   [getWP]
*   description : WordPressの情報を取得・表示
============================================================*/
import axios from 'axios';

const decodeAndDisplayImages = (element) => {
  const images = element.querySelectorAll('img');
  images.forEach(img => {
    const base64Data = img.getAttribute('data-src');
    if (base64Data) {
      img.src = base64Data;
    }
  });
};

const getSpecialFeature = () => {
  const specialFeature = document.getElementById('specialFeature');

  if (specialFeature) {
    axios.get('/apps/note/colorme-special-feature/')
    .then(response => {
      const data = response.data;
      const tempElement = document.createElement('div');
      tempElement.innerHTML = data;
      decodeAndDisplayImages(tempElement);
      const content = tempElement.querySelector('#specialFeatureContent').innerHTML;
      specialFeature.innerHTML = content;
    });
  }
};

const getPickUp = () => {
  const pickUp = document.getElementById('pickUp');

  if (pickUp) {
    axios.get('/apps/note/colorme-pick-up/')
    .then(response => {
      const data = response.data;
      const tempElement = document.createElement('div');
      tempElement.innerHTML = data;
      decodeAndDisplayImages(tempElement);
      const content = tempElement.querySelector('#pickUpContent').innerHTML;
      pickUp.innerHTML = content;
    });
  }
};

const getNewArticles = () => {
  const newArticles = document.getElementById('newArticles');

  if (newArticles) {
    axios.get('/apps/note/colorme-new-articles/')
    .then(response => {
      const data = response.data;
      const tempElement = document.createElement('div');
      tempElement.innerHTML = data;
      decodeAndDisplayImages(tempElement);

      const content = tempElement.querySelector('#newArticlesContent').innerHTML;
      newArticles.innerHTML = content;
    });
  }
};

document.addEventListener('DOMContentLoaded', getSpecialFeature);
document.addEventListener('DOMContentLoaded', getPickUp);
document.addEventListener('DOMContentLoaded', getNewArticles);
