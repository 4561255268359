/**============================================================
*   [bannerSlider]
*   description : バナースライダー機能
============================================================*/
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const bannerSlide = () => {
  const slider = document.getElementById('bannerSlide');
  if (slider) {
    new Splide('#bannerSlide', {
      type: "loop",
      arrows: false,
      pagination: false,
      drag: "free",
      perPage: 3,
      gap: 24,
      autoScroll: {
        speed: 1,
        pauseOnHover: false,
      },
      breakpoints: {
        767: {
          perPage: 1,
          gap: 16,
          autoScroll: {
            speed: 0.5,
          },
        },
      },
    }).mount({ AutoScroll });
  }
};

document.addEventListener('DOMContentLoaded', bannerSlide);
