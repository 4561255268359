/**============================================================
*   [productSlider]
*   description : 商品ページスライド
============================================================*/
import Splide from '@splidejs/splide';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

const productSlider = () => {
  const productMainSlider = document.getElementById('main-slider');
  const productVerticalSlider = document.getElementById('vertical-slider');

  if (productMainSlider && productVerticalSlider) {
    const main = new Splide('#main-slider', {
      cover: true,
      arrows: false,
    });

    const vertical = new Splide('#vertical-slider', {
      width: '100%',
      height: '100%',
      direction: 'ttb',
      isNavigation: true,
      pagination: false,
      arrows: false,
      cover: true,
      focus: 'center',
      trimSpace: true,
      breakpoints: {
        959: {
          direction: 'ltr',
          perPage: 3,
          focus: 'center',
          trimSpace: true,
        },
      }
    });

    main.sync(vertical).mount();
    vertical.mount();

    // スライド画像のポップアップ（PhotoSwipe）
    const target = document.querySelector('[data-pswp]');
    if(!target){
      return;
    }

    const slides = target.querySelectorAll('a');

    slides.forEach((el) => {
      const img = el.querySelector('img');
      el.setAttribute('data-pswp-width', img.naturalWidth);
      el.setAttribute('data-pswp-height', img.naturalHeight);

      const li = img.closest(".splide__slide");
      const imgUrl = img.getAttribute('src');
      li.style.backgroundImage = `url('${imgUrl}')`;
      img.style.opacity = '0';
    });

    // PhotoSwipeLightboxの初期化と設定
    const lightbox = new PhotoSwipeLightbox({
      gallery: target,
      children: 'a',
      initialZoomLevel: 'fit',
      secondaryZoomLevel: 1.5,
      maxZoomLevel: 1,
      showHideAnimationType: 'zoom',
      hideAnimationDuration: 0,
      pswpModule: PhotoSwipe
    });

    if(!Splide){
      lightbox.init();
      return;
    }

    lightbox.on('uiRegister', function() {
      lightbox.pswp.ui.registerElement({
          name: 'custom-caption',
          order: 9,
          isButton: false,
          appendTo: 'root',
          html: 'Caption text',
          onInit: (el) => {
            lightbox.pswp.on('change', () => {
              const currSlideElement = lightbox.pswp.currSlide.data.element;
              let captionHTML = '';
              if (currSlideElement) {
                const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
                if (hiddenCaption) {
                  captionHTML = hiddenCaption.innerHTML;
                } else {
                  captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
                }
              }
              el.innerHTML = captionHTML || '';
            });
          }
      });
    });

    const backEasing = {
      in: 'none'
    };

    lightbox.on('close', () => {
      lightbox.pswp.options.easing = backEasing.in;
    });

    lightbox.init();
  }
}
//document.addEventListener('DOMContentLoaded', productSlider);
window.onload = function () {
  productSlider();
}
