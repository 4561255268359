/**============================================================
*   [footerAccordion]
*   description : フッター内のアコーディオン処理
============================================================*/
let currentWidth = window.innerWidth;

const footerTitleAccordion = () => {
  if (window.innerWidth <= 767) {
    const titleElements = document.getElementsByClassName('l-footer-item__title');
    for (var i = 0; i < titleElements.length; i++) {
      const titleElement = titleElements[i];
      if (!titleElement.hasEventListener) {
        titleElement.addEventListener('click', function () {
          const listItem = this.closest('.l-footer-item');
          if (listItem) {
            listItem.classList.toggle('is-open');
          }
        });
        titleElement.hasEventListener = true;
      }
    }
  }
}

window.addEventListener('DOMContentLoaded', footerTitleAccordion);
window.addEventListener('resize', debounce(function () {
  if (currentWidth == window.innerWidth) {
    return;
  }
  currentWidth = window.innerWidth;
  footerTitleAccordion();
}, 200));

function debounce(func, delay) {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}
